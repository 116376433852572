@media (max-width: 400px) {
  .give-pro-container {
    font-size: small;
    padding: 15px;
    min-width: 95%;
    gap: 0px;
  }
  .page-title {
    font-size: 18px;
    padding: 10px;
  }
  .search-input {
    border: 1px solid #4b92f0;
    padding: 5px 10px;
    font-size: small;
    width: 100%;
    outline-color: #4b92f0;
  }
  .search-label {
    font-size: 16px;
  }
  .search-form {
    flex-direction: column;
    gap: 5px;
    align-items: baseline;
    width: 100%;
  }
  .user-info-container {
    margin-top: 10px;
  }
  .toggle-switch {
    transform: scale(0.7);
  }
  .calendar-header {
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .date-picker-container {
    gap: 10px;
    margin-top: 10px;
  }
  .date-picker-label {
    flex-direction: column;
    align-items: baseline;
    gap: 5px;
  }
  .date-picker-input {
    width: 100%;
  }
}
#enterbtn {
  background-color: #4b92f0;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.field {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 5px;
}

/* GivePro Component Styles */
.give-pro-container {
  max-width: 95%;
  /* background: rgba(255, 255, 255, 0.267); */
  backdrop-filter: blur(10px);
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
}

.header-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-button {
  cursor: pointer;
  color: #333;
}

.page-title {
  background-color: rgba(255, 255, 255, 0.267);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  padding: 15px;
  margin: 0 auto;
  border-radius: 8px;
  color: black;
}

.search-container {
  margin-top: 30px;
}

.search-form {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.search-label {
  font-size: 18px;
  font-weight: 500;
}

.search-input {
  padding: 10px;
  border: 2px solid #4b92f0;
  width: 300px;
  outline-color: #4b92f0;

}

.user-info-container {
  margin-top: 20px;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-label {
  font-weight: 500;
  width: 80px;
}

.toggle-switch {
  transform: scale(0.9);
}

.calendar-container {
  transition: all 0.3s ease;
}

.visible {
  opacity: 1;
  transform: scale(1);
  height: auto;
}

.hidden {
  opacity: 0;
  transform: scale(0);
  height: 0;
}

.calendar-header {
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.date-picker-label {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date-picker-input {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
