/* Add this to your link_generator.css file */

/* Style for the app container */
.app-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

/* Style for the left column */
.left-column {
  flex: 1;
  margin-right: 20px;
}

/* Style for the link generator */
.link-generator {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  align-items: 'center';
  justify-content: 'center';

}

.selection {
  padding: 10px
}

/* Style for the generated links */
.generated-links {
  margin-top: 10px;
}

.generated-links p {
  font-weight: bold;
}

.generated-links ul {
  list-style: none;
  padding: 0;
}

.generated-links li {
  margin-top: 5px;
}

/* Style for the right column */
.right-column {
  flex: 1;
}

/* Style for the user table */
.user-table {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Style for the table itself */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Style for table headings */
th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: left;
}

/* Style for table data cells */
td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* Style for delete button */
button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #c0392b;
}
