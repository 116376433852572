/* LinkGenerator.css */



/* Styles for screen width greater than 600px */
@media screen and (min-width: 600px) {
  .mainDiv {
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    margin-right: 100px;
    
    
  }

  .childDiv {
    /* background-color: green; */
    max-width: 450px;
    box-shadow: 0 4px 8px rgba(165, 157, 157, 0.5); 
    min-width: 450px;
    height: 100vh;
    
  scrollbar-width: none; /* For Firefox */

  }
  .childDiv::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
}

.overridehighlightmargin {
  margin: 0px !important
}

/* @media (min-width: 765px) {
  .no-select {
    margin-left: 262px;
    margin-right: 262px;
  }

  
} */

/* .imagedivstyle {
  position: "absolute";
      top: "50%";
      left: "50%";
      transform: "translate(-50%, -50%)"
} */

.animated-button {
  transition: all 0.2s ease-in-out;
}

.animated-button.hovered {
  transform: scale(1.1);
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.link-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.link-type {
  flex: 1;
  text-align: left;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.dropdown {
  margin: 10px;
}

/* Style the label for the dropdown */
label {
  font-size: 18px;
  color: #333;
  margin-right: 10px;
}

/* Style the dropdown select element */
select {
  appearance: none;
  /* Remove default arrow on select element */
  border: 2px solid #007bff;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: none;
}

/* Style the dropdown options */
select option {
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.email-input {
  margin-top: 20px;
}

input[type="email"] {
  width: 20%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

input[type="email"]:hover {
  border-color: #007bff;
}


/* Modal leadcapture design css */


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.692);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
}

.modal-content {
  position: relative;
  background: #fff;
  border-radius: 25px;
  width: 320px;
  max-height: 70vh;
  padding: 80px 20px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.modal-content-inner {
  max-height: calc(70vh - 140px);
  overflow-y: auto;
  scrollbar-width: none;
  
}

.image-wrapper {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 50%;
  z-index: 1;
}

.cross-icon-leadcapture{
  position: absolute;
    top: 20px;
    left: 90%;
    transform: translateX(-50%);
}

.modal-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.modal-content h2 {
  margin: 10px 0 20px;
}

.modal-content input { 
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.modal-content .submit-btn {
  color: white;
  padding: 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  width: 70%;
  align-self: center;
}



.privacy-text {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}


.input-container-lead{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
}

.input-container-lead input{
  margin: 0;
  flex: 0.1
}
.input-container-lead p {
  flex: 0.9;
  text-align: start;
}


/* portrait styling for name job  */
.portraitDiv1{
  flex: 0.7
}

.portraitDiv2{
  display: flex;
  flex: 0.3;
  justify-content: center;
  padding-top: 2%;
}

.fontcheck{
  font-family: "Inter";
  font-weight: 600;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
}

.loading-video {
  width: 300px;
  /* Adjust as needed */
  height: auto;
}

.spinner2 {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #05090c;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.loading-text {
  margin-top: 15px;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}